<template>
  <div id="app">
    <notifications position="top center" class="system-alert" width="100%">
      <template slot="body" slot-scope="{ item, close }">
        <div
          class="container"
          :class="[
            item.type === 'error' ? 'error' : '',
            item.type === 'success' ? 'success' : '',
            item.type === 'info' ? 'info' : '',
          ]"
        >
          <div class="system-alert__card">
            <div class="system-alert__card-close" @click="close">
              <svg class="svg-icon">
                <use xlink:href="#i-cross"></use>
              </svg>
            </div>
            <div class="system-alert__card-title">
              <template v-if="item.title">
                {{ item.title }}
              </template>
              <template v-else>
                {{
                  item.type === "error"
                    ? "Ошибка"
                    : item.type === "success"
                    ? "Успешно"
                    : item.type === "info"
                    ? "Информация"
                    : ""
                }}
              </template>
            </div>
            <div class="system-alert__card-text">
              {{ item.text }}
            </div>
          </div>
          <!--          <div class="system-notice__close" @click="close">-->
          <!--            <svg class="svg-icon">-->
          <!--              <use xlink:href="#i-cross"></use>-->
          <!--            </svg>-->
          <!--          </div>-->
          <!--          <div class="system-notice__content">-->
          <!--            <div class="system-notice__icon">-->
          <!--              <svg class="svg-icon">-->
          <!--                <use-->
          <!--                  v-if="item.type === 'info'"-->
          <!--                  xlink:href="#i-info-bell"-->
          <!--                ></use>-->
          <!--                <use v-if="item.type === 'error'" xlink:href="#i-warning"></use>-->
          <!--                <use-->
          <!--                  v-if="item.type === 'success'"-->
          <!--                  xlink:href="#i-success"-->
          <!--                ></use>-->
          <!--              </svg>-->
          <!--            </div>-->
          <!--            <div class="system-notice__text">{{ item.text }}</div>-->
          <!--          </div>-->
        </div>
      </template>
    </notifications>
    <notifications
      position="top center"
      class="system-notice"
      width="100%"
      group="standard"
    >
      <template slot="body" slot-scope="{ item, close }">
        <div
          class="system-notice__wrap"
          :class="[
            item.type === 'error' ? 'error' : '',
            item.type === 'success' ? 'success' : '',
            item.type === 'info' ? 'info' : '',
          ]"
        >
          <div class="system-notice__close" @click="close">
            <svg class="svg-icon">
              <use xlink:href="#i-close"></use>
            </svg>
          </div>
          <div class="system-notice__content">
            <div class="system-notice__icon">
              <svg class="svg-icon">
                <use v-if="item.type === 'info'" xlink:href="#i-warning"></use>
                <use v-if="item.type === 'error'" xlink:href="#i-warning"></use>
                <use
                  v-if="item.type === 'success'"
                  xlink:href="#i-success"
                ></use>
              </svg>
            </div>
            <div class="system-notice__text">{{ item.text }}</div>
          </div>
        </div>
      </template>
    </notifications>
    <SvgIcons />
    <component :is="layout" v-if="!timeout"></component>
  </div>
</template>

<script>
import SvgIcons from "@/components/icons/SvgIcons.vue";
import storage from "local-storage-fallback";

export default {
  name: "App",
  components: {
    SvgIcons,
  },
  data() {
    return {
      timer: null,
      timeout: true,
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("resize", this.srollbarWidthResize);
  },
  created() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.srollbarWidthResize();
    window.addEventListener("resize", this.srollbarWidthResize);
    const queryParams = new URLSearchParams(window.location.search);
    const refId = queryParams.get("refId");
    if (refId && refId != storage.getItem("refId")) {
      this.timeout = false;
      return;
    }
    if (
      storage.getItem("lastPath") &&
      window.location.pathname == "/" &&
      storage.getItem("lastPath") != window.location.pathname &&
      storage.getItem("auth")
    ) {
      this.$router.push({ path: storage.getItem("lastPath") });
    }
    this.timeout = false;
  },
  methods: {
    handleBeforeUnload() {
      if (
        this.$route.path != "/login" &&
        this.$route.path != "/error-link" &&
        storage.getItem("auth")
      ) {
        storage.setItem("lastPath", this.$route.path);
      }
    },
    srollbarWidthResize() {
      setTimeout(() => {
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          document.body.classList.add("mobile-device");
        } else {
          document.body.classList.remove("mobile-device");
        }
      }, 100);
      let sw = this.srollbarWidth();
      document.documentElement.style.setProperty("--sw", `${sw}px`);
    },
    srollbarWidth() {
      const scrollDiv = document.createElement("div");
      scrollDiv.style.width = "100px";
      scrollDiv.style.height = "100px";
      scrollDiv.style.overflow = "scroll";
      scrollDiv.style.position = "absolute";
      scrollDiv.style.top = "-9999px";
      document.body.appendChild(scrollDiv);
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    },
  },
};
</script>
