import storage from "local-storage-fallback";
import router from "@/router";

export default {
  state: {
    invoiceId: JSON.parse(storage.getItem("invoiceId")),
    userInfo: null,
    needConfirmation: JSON.parse(storage.getItem("needConfirmation")),
  },
  actions: {
    setInvoiceId({ commit }, token) {
      commit("UPDATE_INVOICE_ID", token);
    },
    setUserInfo({ commit }, info) {
      commit("UPDATE_USER_INFO", info);
    },
    setNeedConfirmation({ commit }, needConfirmation) {
      commit("UPDATE_NEED_CONFIRMATION", needConfirmation);
    },
    setLogout({ commit }) {
      commit("LOGOUT");
    },
  },
  mutations: {
    UPDATE_INVOICE_ID: (state, invoiceId) => {
      state.invoiceId = invoiceId;
      storage.setItem("invoiceId", JSON.stringify(invoiceId));
    },
    UPDATE_USER_INFO: (state, info) => {
      state.userInfo = info;
    },
    UPDATE_NEED_CONFIRMATION: (state, needConfirmation) => {
      state.needConfirmation = needConfirmation;
      if (needConfirmation == null) {
        storage.removeItem("needConfirmation");
        return;
      }
      if (needConfirmation) {
        storage.setItem("needConfirmation", true);
      } else {
        storage.setItem("needConfirmation", false);
      }
    },
    LOGOUT: () => {
      // storage.removeItem("invoiceId");
      // storage.removeItem("transactionId");
      // storage.removeItem("paymentToolId");
      // storage.removeItem("needConfirmation");
      // storage.removeItem("selectedInstallmentProfile");
      // storage.removeItem("successInstallment");
      storage.removeItem("auth");
      if (storage.getItem("refIdNew")) {
        router.push({
          name: "Вход",
          query: { refId: storage.getItem("refIdNew") },
        });
      } else if (storage.getItem("refId")) {
        router.push({
          name: "Вход",
          query: { refId: storage.getItem("refId") },
        });
      } else {
        router.push({
          name: "Вход",
        });
      }
    },
  },
  getters: {
    getNeedConfirmation(state) {
      return state.needConfirmation;
    },
    getInvoiceId(state) {
      return state.invoiceId;
    },
    getUserInfo(state) {
      const userInfo = state.userInfo;
      if (userInfo) {
        userInfo.fullname =
          userInfo?.personalInfo?.lastName ||
          userInfo?.personalInfo?.firstName ||
          userInfo?.personalInfo?.middleName
            ? `${
                userInfo.personalInfo.firstName
                  ? userInfo.personalInfo.firstName
                  : ""
              } ${
                userInfo.personalInfo.middleName
                  ? userInfo.personalInfo.middleName[0] + ". "
                  : ""
              } ${
                userInfo.personalInfo.lastName
                  ? userInfo.personalInfo.lastName
                  : ""
              }`
            : "";
      }
      return userInfo;
    },
  },
};
