var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('notifications',{staticClass:"system-alert",attrs:{"position":"top center","width":"100%"},scopedSlots:_vm._u([{key:"body",fn:function({ item, close }){return [_c('div',{staticClass:"container",class:[
          item.type === 'error' ? 'error' : '',
          item.type === 'success' ? 'success' : '',
          item.type === 'info' ? 'info' : '',
        ]},[_c('div',{staticClass:"system-alert__card"},[_c('div',{staticClass:"system-alert__card-close",on:{"click":close}},[_c('svg',{staticClass:"svg-icon"},[_c('use',{attrs:{"xlink:href":"#i-cross"}})])]),_c('div',{staticClass:"system-alert__card-title"},[(item.title)?[_vm._v(" "+_vm._s(item.title)+" ")]:[_vm._v(" "+_vm._s(item.type === "error" ? "Ошибка" : item.type === "success" ? "Успешно" : item.type === "info" ? "Информация" : "")+" ")]],2),_c('div',{staticClass:"system-alert__card-text"},[_vm._v(" "+_vm._s(item.text)+" ")])])])]}}])}),_c('notifications',{staticClass:"system-notice",attrs:{"position":"top center","width":"100%","group":"standard"},scopedSlots:_vm._u([{key:"body",fn:function({ item, close }){return [_c('div',{staticClass:"system-notice__wrap",class:[
          item.type === 'error' ? 'error' : '',
          item.type === 'success' ? 'success' : '',
          item.type === 'info' ? 'info' : '',
        ]},[_c('div',{staticClass:"system-notice__close",on:{"click":close}},[_c('svg',{staticClass:"svg-icon"},[_c('use',{attrs:{"xlink:href":"#i-close"}})])]),_c('div',{staticClass:"system-notice__content"},[_c('div',{staticClass:"system-notice__icon"},[_c('svg',{staticClass:"svg-icon"},[(item.type === 'info')?_c('use',{attrs:{"xlink:href":"#i-warning"}}):_vm._e(),(item.type === 'error')?_c('use',{attrs:{"xlink:href":"#i-warning"}}):_vm._e(),(item.type === 'success')?_c('use',{attrs:{"xlink:href":"#i-success"}}):_vm._e()])]),_c('div',{staticClass:"system-notice__text"},[_vm._v(_vm._s(item.text))])])])]}}])}),_c('SvgIcons'),(!_vm.timeout)?_c(_vm.layout,{tag:"component"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }