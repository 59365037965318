<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style="
      position: absolute;
      top: -99999px;
      left: -99999px;
      height: 0;
      width: 0;
      visibility: hidden;
    "
  >
    <symbol id="i-logo" viewBox="0 0 125 51">
      <path d="M25.5 51A25.5 25.5 0 1 0 0 25.5 25.5 25.5 0 0 0 25.5 51Z" />
      <path
        d="M12.21 31.62a2.47 2.47 0 1 0 4.94 0v-1a.72.72 0 0 1 .72-.72h1a2.46 2.46 0 0 0 0-4.91h-1a.71.71 0 0 1-.72-.71v-1a2.47 2.47 0 0 0-4.94 0v1a.71.71 0 0 1-.72.71h-1a2.46 2.46 0 0 0 0 4.91h1a.72.72 0 0 1 .72.72Z"
        style="fill: #fff; fill-rule: evenodd"
      />
      <path
        d="M22.42 14.21a3.11 3.11 0 0 0 0 6.22h6.16a1.2 1.2 0 0 1 1.07 1.73l-6.93 14a3.1 3.1 0 1 0 5.56 2.75l10-20.16a3.09 3.09 0 0 0-.74-3.71 3.13 3.13 0 0 0-2.13-.84Z"
        style="fill: #fff; fill-rule: evenodd"
      />
      <path
        d="M72.46 28.69h-7.07v-4.46h7.07a5.71 5.71 0 0 0 2.67-.53 3.46 3.46 0 0 0 1.51-1.52 5 5 0 0 0 .47-2.2 5.7 5.7 0 0 0-.47-2.3A3.91 3.91 0 0 0 75.13 16a4.93 4.93 0 0 0-2.67-.64h-5.08v23.22h-5.72V10.84h10.8A12.18 12.18 0 0 1 78.05 12a8.44 8.44 0 0 1 3.58 3.22A8.91 8.91 0 0 1 82.87 20a8.5 8.5 0 0 1-1.24 4.65 8.18 8.18 0 0 1-3.58 3 13.34 13.34 0 0 1-5.59 1.04Zm24.91 5.24v-9.18a4.16 4.16 0 0 0-.32-1.7 2.44 2.44 0 0 0-1-1.12 3.35 3.35 0 0 0-1.74-.4 3.68 3.68 0 0 0-1.58.32 2.26 2.26 0 0 0-1 .9 2.47 2.47 0 0 0-.36 1.35h-5.53a5.34 5.34 0 0 1 .61-2.5 6.3 6.3 0 0 1 1.77-2.07A8.75 8.75 0 0 1 91 18.1a11.68 11.68 0 0 1 3.62-.52 10.84 10.84 0 0 1 4.27.8 6.61 6.61 0 0 1 2.93 2.4 7 7 0 0 1 1.09 4v8.82a15.06 15.06 0 0 0 .21 2.78 7.33 7.33 0 0 0 .61 1.87v.32h-5.56a7.87 7.87 0 0 1-.61-2.09 17 17 0 0 1-.19-2.55Zm.72-7.93v3.11h-3.03a6.2 6.2 0 0 0-1.88.25 3.53 3.53 0 0 0-1.32.7 2.91 2.91 0 0 0-.76 1 3.66 3.66 0 0 0-.23 1.3 2.54 2.54 0 0 0 .33 1.28 2.24 2.24 0 0 0 .93.87 3.21 3.21 0 0 0 1.43.31 4.46 4.46 0 0 0 2.15-.5 4 4 0 0 0 1.41-1.22 2.54 2.54 0 0 0 .54-1.37l1.44 2.37a8.89 8.89 0 0 1-.83 1.62A8.08 8.08 0 0 1 97 37.31a6.81 6.81 0 0 1-2 1.2 7.17 7.17 0 0 1-2.67.45 8.06 8.06 0 0 1-3.57-.78A6.44 6.44 0 0 1 86.28 36a5.76 5.76 0 0 1-.92-3.2 6.62 6.62 0 0 1 .64-2.89 5.61 5.61 0 0 1 1.8-2.11 8.22 8.22 0 0 1 3-1.32 17.09 17.09 0 0 1 4.11-.48Zm14.85 10.24L118.41 18h5.89L116 41.69a12.79 12.79 0 0 1-.73 1.68 6.72 6.72 0 0 1-1.2 1.69 5.61 5.61 0 0 1-1.88 1.34 6.69 6.69 0 0 1-2.77.51 6.33 6.33 0 0 1-1.27-.1c-.34-.06-.73-.15-1.19-.26v-4h.88a5.41 5.41 0 0 0 1.77-.25 2.54 2.54 0 0 0 1.11-.76 3.61 3.61 0 0 0 .66-1.3ZM110.65 18l4.48 14.94.78 5.82-3.73.4L104.76 18Z"
      />
    </symbol>
    <symbol id="i-loader" viewBox="0 0 95 94">
      <path d="M47.5 94a47 47 0 1 0-47-47 47 47 0 0 0 47 47Z" />
      <path
        d="M23 58.28a4.56 4.56 0 0 0 9.11 0v-1.89a1.33 1.33 0 0 1 1.32-1.32h1.88a4.52 4.52 0 0 0 0-9h-1.88a1.33 1.33 0 0 1-1.32-1.32v-1.92a4.56 4.56 0 1 0-9.11 0v1.88A1.33 1.33 0 0 1 21.68 46H19.8a4.52 4.52 0 1 0 0 9h1.88A1.33 1.33 0 0 1 23 56.39Z"
        style="fill: #fff; fill-rule: evenodd"
      />
      <path
        d="M41.83 26.19a5.74 5.74 0 1 0 0 11.47h11.34a2.2 2.2 0 0 1 2 3.18l-12.8 25.82a5.72 5.72 0 1 0 10.26 5.08L71 34.59a5.9 5.9 0 0 0-5.29-8.4Z"
        style="fill: #fff; fill-rule: evenodd"
      />
    </symbol>
    <symbol id="i-info-circle" viewBox="0 0 20 21">
      <path
        d="M9.08 14.25a.84.84 0 0 0 .84.83h.16a.84.84 0 0 0 .84-.83v-3.83a.85.85 0 0 0-.84-.84h-.16a.85.85 0 0 0-.84.84Zm.92-6.5a.91.91 0 0 0 .92-.92.88.88 0 0 0-.27-.65.94.94 0 0 0-1.3 0 .88.88 0 0 0-.27.65.91.91 0 0 0 .92.92Zm0 11.92a8.82 8.82 0 0 1-3.58-.73 9.41 9.41 0 0 1-2.91-2 9.62 9.62 0 0 1-2-2.91 9 9 0 0 1-.68-3.53 8.9 8.9 0 0 1 .72-3.57A9.38 9.38 0 0 1 3.51 4a9.27 9.27 0 0 1 2.91-2A8.82 8.82 0 0 1 10 1.33a8.74 8.74 0 0 1 3.57.73 9.16 9.16 0 0 1 4.87 4.87 9.12 9.12 0 0 1 0 7.15 9.1 9.1 0 0 1-4.87 4.86 8.74 8.74 0 0 1-3.57.73Zm0-1.84a7.07 7.07 0 0 0 5.2-2.13 7.07 7.07 0 0 0 2.13-5.2 7.07 7.07 0 0 0-2.13-5.2A7.07 7.07 0 0 0 10 3.17 7.07 7.07 0 0 0 4.8 5.3a7.07 7.07 0 0 0-2.13 5.2 7.07 7.07 0 0 0 2.13 5.2 7.07 7.07 0 0 0 5.2 2.13Z"
      />
    </symbol>
    <symbol id="i-check" viewBox="0 0 20 21">
      <path
        d="M10 14.67a.83.83 0 0 1-.59-.25L2.74 7.76a.85.85 0 0 1 0-1.18.83.83 0 0 1 1.18 0L10 12.65l6.08-6.07a.82.82 0 0 1 1.17 0 .83.83 0 0 1 0 1.18l-6.66 6.66a.83.83 0 0 1-.59.25Z"
      />
    </symbol>
    <symbol id="i-cross" viewBox="0 0 24 24">
      <path
        d="M6 19a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l12-12a1 1 0 1 1 1.42 1.42l-12 12A1 1 0 0 1 6 19Z"
      />
      <path
        d="M18 19a1 1 0 0 1-.71-.29l-12-12a1 1 0 0 1 1.42-1.42l12 12a1 1 0 0 1 0 1.42A1 1 0 0 1 18 19Z"
      />
    </symbol>
    <symbol id="i-info" viewBox="0 0 24 24">
      <path
        d="M12.0003 10.3554C12.5453 10.3554 12.9871 10.7972 12.9871 11.3422V17.921C12.9871 18.466 12.5453 18.9078 12.0003 18.9078C11.4553 18.9078 11.0135 18.466 11.0135 17.921V11.3422C11.0135 10.7972 11.4553 10.3554 12.0003 10.3554Z"
        fill="#006C8B"
      />
      <path
        d="M12.0003 8.05286C12.727 8.05286 13.3161 7.46378 13.3161 6.73712C13.3161 6.01045 12.727 5.42137 12.0003 5.42137C11.2737 5.42137 10.6846 6.01045 10.6846 6.73712C10.6846 7.46378 11.2737 8.05286 12.0003 8.05286Z"
        fill="#006C8B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.83835 1.16987C9.90078 0.715837 14.0998 0.715837 18.1623 1.16987C20.5653 1.43845 22.5059 3.33134 22.7886 5.74831C23.2744 9.90201 23.2744 14.0982 22.7886 18.2519C22.5059 20.6689 20.5653 22.5617 18.1623 22.8303C14.0998 23.2844 9.90078 23.2844 5.83835 22.8303C3.43529 22.5617 1.4947 20.6689 1.21202 18.2519C0.726203 14.0982 0.726203 9.90201 1.21202 5.74831C1.4947 3.33134 3.43529 1.43845 5.83835 1.16987ZM17.9431 3.13128C14.0263 2.69352 9.97431 2.69352 6.05756 3.13128C4.55178 3.29957 3.34649 4.48801 3.17227 5.97758C2.70427 9.97896 2.70427 14.0212 3.17227 18.0226C3.34649 19.5122 4.55178 20.7006 6.05756 20.8689C9.97431 21.3067 14.0263 21.3067 17.9431 20.8689C19.4488 20.7006 20.6541 19.5122 20.8284 18.0226C21.2963 14.0212 21.2963 9.97896 20.8284 5.97758C20.6541 4.48801 19.4488 3.29957 17.9431 3.13128Z"
        fill="#006C8B"
      />
      <rect width="24" height="24" fill="white" />
    </symbol>
    <symbol id="i-warning" viewBox="0 0 18 18">
      <path
        d="M9 .67A8.33 8.33 0 1 0 17.33 9 8.33 8.33 0 0 0 9 .67Zm.83 12.5H8.17v-5h1.66Zm0-6.67H8.17V4.83h1.66Z"
      />
    </symbol>
    <symbol id="i-success" viewBox="0 0 16 16">
      <path
        d="M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8ZM6.4 12l-4-4 1.13-1.13L6.4 9.74l6.07-6.08L13.6 4.8Z"
      />
    </symbol>
    <symbol id="i-warning-circle" viewBox="0 0 14 14">
      <path d="M0 0h14v14H0z" style="fill: none" />
      <path
        d="M6.36 9.63a.58.58 0 0 0 .58.58h.12a.58.58 0 0 0 .58-.58V6.94a.58.58 0 0 0-.58-.58h-.12a.58.58 0 0 0-.58.58ZM7 5.08a.66.66 0 0 0 .46-.19.62.62 0 0 0 .18-.46.61.61 0 0 0-.18-.43.65.65 0 0 0-.92 0 .61.61 0 0 0-.18.45.62.62 0 0 0 .18.46.66.66 0 0 0 .46.17Zm0 8.34a6.18 6.18 0 0 1-2.5-.51A6.37 6.37 0 0 1 1.09 9.5 6.18 6.18 0 0 1 .58 7a6.18 6.18 0 0 1 .51-2.5A6.37 6.37 0 0 1 4.5 1.09 6.18 6.18 0 0 1 7 .58a6.18 6.18 0 0 1 2.5.51 6.37 6.37 0 0 1 3.41 3.41 6.18 6.18 0 0 1 .51 2.5 6.18 6.18 0 0 1-.51 2.5 6.37 6.37 0 0 1-3.41 3.41 6.18 6.18 0 0 1-2.5.51Zm0-1.29a4.93 4.93 0 0 0 3.64-1.49A4.93 4.93 0 0 0 12.13 7a4.93 4.93 0 0 0-1.49-3.64A4.93 4.93 0 0 0 7 1.87a4.93 4.93 0 0 0-3.64 1.49A4.93 4.93 0 0 0 1.87 7a4.93 4.93 0 0 0 1.49 3.64A4.93 4.93 0 0 0 7 12.13Z"
      />
    </symbol>
    <symbol id="i-edit" viewBox="0 0 24 24">
      <path
        d="M5.75,17.38A.87.87,0,0,0,7.24,18l6.69-6.69a.87.87,0,0,0-1.23-1.23L6,16.76A.87.87,0,0,0,5.75,17.38ZM18.91,8.8a.88.88,0,0,1-1.24,0L15.19,6.35a.87.87,0,0,1,0-1.24l.6-.61a1.79,1.79,0,0,1,2.48,0L19.5,5.73A1.74,1.74,0,0,1,20,7a1.53,1.53,0,0,1-.48,1.21ZM16.4,10.09a.88.88,0,0,1,0,1.24L8,19.74a.84.84,0,0,1-.62.26H4.88A.87.87,0,0,1,4,19.12V16.64A.84.84,0,0,1,4.26,16L12.67,7.6a.88.88,0,0,1,1.24,0Z"
      />
    </symbol>
    <symbol id="i-calendar" viewBox="0 0 18 19">
      <path
        d="M14.25 17.75H3.75A2.25 2.25 0 0 1 1.5 15.5V5a2.25 2.25 0 0 1 2.25-2.25h10.5A2.25 2.25 0 0 1 16.5 5v10.5a2.25 2.25 0 0 1-2.25 2.25ZM3.75 4.25A.76.76 0 0 0 3 5v10.5a.76.76 0 0 0 .75.75h10.5a.76.76 0 0 0 .75-.75V5a.76.76 0 0 0-.75-.75Z"
      />
      <path
        d="M12 5.75a.76.76 0 0 1-.75-.75V2a.75.75 0 0 1 1.5 0v3a.76.76 0 0 1-.75.75ZM6 5.75A.76.76 0 0 1 5.25 5V2a.75.75 0 0 1 1.5 0v3a.76.76 0 0 1-.75.75ZM15.75 8.75H2.25a.75.75 0 0 1 0-1.5h13.5a.75.75 0 0 1 0 1.5Z"
      />
    </symbol>
    <symbol id="i-check2" viewBox="0 0 24 24">
      <path
        d="M9.56 18.81a1 1 0 0 1-1.41 0l-5.46-5.46A1 1 0 1 1 4.06 12l4.09 4.09a1 1 0 0 0 1.41 0l10.38-10.4a1 1 0 0 1 1.37 1.37Z"
      />
    </symbol>
    <symbol id="i-download" viewBox="0 0 20 21">
      <path
        d="M15.83 18.83H4.17a2.49 2.49 0 0 1-2.5-2.5V13a.83.83 0 0 1 1.66 0v3.33a.82.82 0 0 0 .25.59.8.8 0 0 0 .59.25h11.66a.85.85 0 0 0 .84-.84V13a.83.83 0 0 1 1.66 0v3.33a2.49 2.49 0 0 1-2.5 2.5Z"
      />
      <path
        d="M10 13.83a.82.82 0 0 1-.59-.24L5.24 9.42a.83.83 0 0 1 1.18-1.18L10 11.82l3.58-3.58a.83.83 0 0 1 1.17 1.18l-4.16 4.17a.82.82 0 0 1-.59.24Z"
      />
      <path
        d="M10 13.83a.83.83 0 0 1-.83-.83V3a.83.83 0 1 1 1.66 0v10a.83.83 0 0 1-.83.83Z"
      />
    </symbol>
    <symbol id="i-eye" viewBox="0 0 18 16">
      <path
        d="M9 11.75a3.35 3.35 0 0 0 3.37-3.37A3.35 3.35 0 0 0 9 5a3.36 3.36 0 0 0-3.38 3.38A3.36 3.36 0 0 0 9 11.75Zm0-1.35a2 2 0 0 1-1.43-.59 2 2 0 0 1 0-2.87 2 2 0 0 1 2.86 0A1.92 1.92 0 0 1 11 8.38a1.92 1.92 0 0 1-.59 1.43A2 2 0 0 1 9 10.4ZM9 14a8.67 8.67 0 0 1-5-1.53A8.65 8.65 0 0 1 .86 8.65a.75.75 0 0 1 0-.55A8.65 8.65 0 0 1 4 4.28a8.67 8.67 0 0 1 5-1.53 8.69 8.69 0 0 1 5 1.53 8.65 8.65 0 0 1 3.14 3.82.75.75 0 0 1 0 .55A8.65 8.65 0 0 1 14 12.47 8.69 8.69 0 0 1 9 14Z"
      />
    </symbol>
    <symbol id="i-eye-closed" viewBox="0 0 18 16">
      <path
        d="M15.37 15.58a.74.74 0 0 1-1 0L12 13.3a.8.8 0 0 0-.75-.19c-.3.08-.59.14-.89.19a8.88 8.88 0 0 1-1.36.1 8.45 8.45 0 0 1-5-1.57A8.84 8.84 0 0 1 .86 8.05a.75.75 0 0 1 0-.55 9.6 9.6 0 0 1 .88-1.57 8.53 8.53 0 0 1 .8-1 .81.81 0 0 0 0-1.12l-1-1a.74.74 0 0 1 0-1 .75.75 0 0 1 1.06 0l12.77 12.71a.75.75 0 0 1 0 1.06ZM9 11.15h.38a.2.2 0 0 0 .11-.35L6 7.28a.21.21 0 0 0-.36.11v.39A3.35 3.35 0 0 0 9 11.15Zm6-.08a.71.71 0 0 1-1 0l-1.55-1.59a.84.84 0 0 1-.19-.83v-.17a3.19 3.19 0 0 0 .08-.7A3.36 3.36 0 0 0 9 4.4a3.86 3.86 0 0 0-.7.07h-.16a.86.86 0 0 1-.86-.18l-.75-.75a.67.67 0 0 1 .27-1.11 4.7 4.7 0 0 1 .51-.12A8.43 8.43 0 0 1 9 2.15a8.45 8.45 0 0 1 5 1.57 8.84 8.84 0 0 1 3.1 3.78.75.75 0 0 1 0 .55 8.73 8.73 0 0 1-1 1.78 8.39 8.39 0 0 1-1.1 1.24Zm-4-3.9c.11.35-.3.51-.56.24l-.93-.93c-.29-.29-.11-.76.27-.61a2 2 0 0 1 .76.52 1.93 1.93 0 0 1 .4.78Z"
      />
    </symbol>
  </svg>
</template>

<script>
export default {
  name: "SvgIcons",
};
</script>
