<template>
  <div class="page-wrapper">
    <Header />
    <div class="page">
      <div class="container">
        <router-view />
      </div>
    </div>
    <!--    <Footer />-->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: "Default",
  components: {
    Header,
  },
};
</script>
